import * as Sentry from '@sentry/browser';

import { isBrowser } from './utils';

export const sendToSentry = (message: string, data = {}, level = 'info') => {
  if (isBrowser && Sentry) {
    Sentry.withScope((scope: any) => {
      Object.keys(data).forEach((key) => {
        scope.setExtra(key, data[key]);
      });

      scope.setLevel(level);
      Sentry.captureMessage(message);
    });
  }
};
