import * as configcat from 'configcat-js-ssr';

const getConfigCatToken = () => {
  const configCatToken = process.env.GATSBY_CONFIGCAT_TOKEN;

  if (!configCatToken) throw Error('ConfigCat token not present');

  return configCatToken;
};

export const configCatClient = configcat.getClient(getConfigCatToken(), configcat.PollingMode.ManualPoll, {
  dataGovernance: configcat.DataGovernance.EuOnly,
});

export enum FeatureFlags {
  MARKET_IS_DESTINATION_ENABLED = 'market_is_destination_enabled',
  MARKET_IS_HOME_HERO_ENABLED = 'market_is_home_hero_enabled',
  MARKET_IS_HOME_SERVICES_ENABLED = 'market_is_home_services_enabled',
  MARKET_IS_EXPERT_SECTION_ENABLED = 'market_is_expert_section_enabled',
  MARKET_IS_DESTINATION_LIST_ENABLED = 'market_is_destination_list_enabled',
  MARKET_IS_TRUSTPILOT_REVIEWS_ENABLED = 'market_is_trustpilot_reviews_enabled',
  HAS_STICKY_SEARCH = 'has_sticky_search',
  MARKET_IS_NEW_SEARCH_BAR_ENABLED = 'market_is_new_search_bar_enabled',
  AB_CONTENT_OFFERS_ORDER = 'ab_content_offers_order',
  AB_TS_LINK_SECTION = 'ab_ts_link_section',
  AB_CONTENT_BOOKING_PROCESS = 'ab_content_booking_process',
  AB_POPUP_VOUCHER = 'ab_popup_voucher',
}
