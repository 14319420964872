import { appMeta } from '@shared/utils/appMeta';

export type NonNullableProps<T> = T & {
  [P in keyof T]: NonNullable<T[P]>;
};

export const nonNullableIdentity = <T,>(obj: T) => obj as NonNullableProps<T>;

/**
 * Filters empty values out of array.
 *
 * Returns null if null or undefined was passed, array of values otherwise.
 */
export const safeMap = <T, U>(array: (T | null | undefined)[], mapper: (e: T, i: number, array: T[]) => U) =>
  array.filter((e) => e != null).map(mapper);

/**
 * Replaces placeholders in template string with values from sourceObject.
 *
 * E.g. for
 * sourceObj = { name: "Africa" }, template = "Welcome to ${name}"
 * will return "Welcome to Africa"
 */
export const replacePlaceholders = (sourceObj: any, template: string) => {
  const placeholderRegExp = /\${(\w+)}/g;
  if (placeholderRegExp.exec(template) !== null) {
    return template.replace(placeholderRegExp, (_, key) => sourceObj[key]);
  }

  return template;
};

const getCurrencyForLocale = (locale: string) => {
  const currencyMapper = {
    'de-DE': 'EUR',
    'fr-FR': 'EUR',
    'en-US': 'USD',
  };

  return currencyMapper[locale];
};

// This fn serves to achieve cohesion across website when it comes to currency.
// Returns ISO-standard specific amount along with properly placed currency symbol.
export const formatPrice = (amount: number) => {
  const { locale } = appMeta;

  return amount.toLocaleString(locale, {
    style: 'currency',
    currency: getCurrencyForLocale(locale),
    minimumFractionDigits: 0, // assuming we dont have offers with cents, here and below
    maximumFractionDigits: 0,
  });
};

export const getCurrencySymbol = () => formatPrice(0).replace(/\d/g, '').trim();

export const makeAbsoluteUrl = (path: string, trailingSlash = false) => {
  // First we need to trim any slashes (/) from the host and path.
  const normalisedHost = process.env.GATSBY_HOST.replace(/\/*$/, '');
  const normalisedPath = path ? path.replace(/(^\/*)|(\/*$)/g, '') : '';

  let url = `${normalisedHost}/${normalisedPath}`;

  // Append an extra slash, if requested.
  if (normalisedPath && trailingSlash) {
    url += '/';
  }

  return url;
};

/**
 * Returns an array of unique objects based on the provided key
 * @param arr - Array with none unique objects
 * @param key - object's key to make a unique array based on
 * @returns Unique array of objects
 */
export const createUniqueArray = <T,>(arr: T[], key: string): T[] => {
  return arr.filter((value, index, self) => index === self.findIndex((t) => t[key] === value[key]));
};

export type DeepRequired<T> = Required<{
  [K in keyof T]: DeepRequired<NonNullable<T[K]>>;
}>;

export const isBrowser = typeof window !== 'undefined';

// NOTE: Please do not use this function when you need to format a price.
// Reason: When the locale is 'fr-FR', the price will be formatted as '1 000' instead of '1.000'.
export const formatNumber = (value: number) => {
  const { locale } = appMeta;

  return Number(value).toLocaleString(locale);
};
