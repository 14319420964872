import { CHANNEL_COOKIE_NAME, GTM_EVENT_LABELS, snowplow } from '@tourlane/tracking';

import { FlagState } from '@shared/components/FeatureFlag';
import { Atom, Molecule } from '@shared/types/tracking.types';

import { getCookie } from './cookies';

export interface TrackingProps {
  atom: string;
  molecule: string;
  label: string;
  destination?: string;
  pageType?: string;
  action?: string;
  entities?:
    | (
        | {
            schema: string;
            data: {
              [key: string]: any;
            };
          }
        | undefined
      )[]
    | undefined;
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

enum CUSTOM_GTM_EVENT_LABELS {
  NEWSLETTER_FORM_SUBMITTED = 'newsletter_form_submitted',
}

type GTMEventVariables = {
  event: GTM_EVENT_LABELS | CUSTOM_GTM_EVENT_LABELS;
  channel: any;
  [key: string]: string;
};

export type DataObject = Record<string, string>;

export const handleTracking = ({
  atom,
  molecule,
  label,
  pageType = '',
  action = snowplow.Action.CLICK,
  entities,
  destination = '',
}: TrackingProps) => {
  snowplow.trackSelfDescribingEvent({
    event: {
      schema: snowplow.Schema.COMPONENT_EVENT,
      data: {
        action,
        molecule,
        atom,
        label,
      },
    },
    pageType,
    destination,
    entities,
  });
};

export const trackFeatureFlags = (featureFlags: FlagState) => {
  const snoplowList = Object.entries(featureFlags).map(([key, value]) => ({
    schema: snowplow.Schema.EXPERIMENT_ENTITY,
    data: {
      variation: key,
      value: String(value),
    },
  }));

  snowplow.addToGlobalContext(snoplowList);
};

export const Action = snowplow.Action;
export const PageType = snowplow.PageType;
export const TrackingSchemas = snowplow.Schema;

const isDataLayerDefined = () => typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined';

export const trackGTMEvent = (variables: GTMEventVariables) => {
  isDataLayerDefined() && window.dataLayer.push(variables);
};

export const LANDING_PAGE_LOADED_EVENT = {
  event: GTM_EVENT_LABELS.LANDING_PAGE_LOADED,
  channel: getCookie(CHANNEL_COOKIE_NAME),
};

export const trackLandingPageLoadedGTMEvent = (destinationTracking: DataObject) => {
  if (Object.keys(destinationTracking).length === 0) {
    return;
  }

  trackGTMEvent({
    ...LANDING_PAGE_LOADED_EVENT,
    ...destinationTracking,
  });
};

export const trackNewsletterFormSubmittedGTMEvent = (data: DataObject) =>
  trackGTMEvent({
    event: CUSTOM_GTM_EVENT_LABELS.NEWSLETTER_FORM_SUBMITTED,
    channel: getCookie(CHANNEL_COOKIE_NAME),
    ...data,
  });

export const trackScheduleAFreeCallClick = (props: Pick<TrackingProps, 'label' | 'destination' | 'pageType'>) =>
  handleTracking({
    atom: Atom.BRIDGE_SCHEDULE_CALL,
    molecule: Molecule.SELF_SCHEDULING,
    action: Action.CLICK,
    ...props,
  });

export const trackCallScheduledGTMEvent = (data: DataObject) =>
  trackGTMEvent({
    event: CUSTOM_GTM_EVENT_LABELS.CALL_SCHEDULED,
    channel: getCookie(CHANNEL_COOKIE_NAME),
    ...data,
  });
