import { FeatureFlags } from './FeatureFlag.config';

export enum FlagValuesExperiment {
  control = 'control',
  test = 'test',
  excluded = 'excluded',
}
export interface FlagState {
  [FeatureFlags.MARKET_IS_DESTINATION_ENABLED]: boolean;
  [FeatureFlags.MARKET_IS_HOME_HERO_ENABLED]: boolean;
  [FeatureFlags.MARKET_IS_HOME_SERVICES_ENABLED]: boolean;
  [FeatureFlags.MARKET_IS_EXPERT_SECTION_ENABLED]: boolean;
  [FeatureFlags.MARKET_IS_DESTINATION_LIST_ENABLED]: boolean;
  [FeatureFlags.MARKET_IS_TRUSTPILOT_REVIEWS_ENABLED]: boolean;
  [FeatureFlags.HAS_STICKY_SEARCH]: boolean;
  [FeatureFlags.MARKET_IS_NEW_SEARCH_BAR_ENABLED]: boolean;
  [FeatureFlags.AB_CONTENT_OFFERS_ORDER]: FlagValuesExperiment;
  [FeatureFlags.AB_TS_LINK_SECTION]: FlagValuesExperiment;
  [FeatureFlags.AB_CONTENT_BOOKING_PROCESS]: FlagValuesExperiment;
  [FeatureFlags.AB_POPUP_VOUCHER]: FlagValuesExperiment;
}
