import { FC, PropsWithChildren, createContext, useContext, useLayoutEffect, useState } from 'react';

import { TOURLANE_ID_COOKIE_NAME } from '@tourlane/tracking';

import { getCookie } from '@shared/utils/cookies';
import { sendToSentry } from '@shared/utils/sentry';
import { trackFeatureFlags } from '@shared/utils/tracking';

import { configCatClient } from './FeatureFlag.config';
import { FlagState } from './FeatureFlag.types';

const FeatureFlagContext = createContext<Partial<FlagState> & { isReady: boolean }>({ isReady: false });

export const FeatureFlagProvider: FC<
  PropsWithChildren & { destinationSlug?: string; ch?: string; onReadyConfigCat?: () => void }
> = ({ destinationSlug, ch, onReadyConfigCat, children }) => {
  const identifier = getCookie(TOURLANE_ID_COOKIE_NAME);

  const [flags, setFlags] = useState<Partial<FlagState>>({});
  const [isReady, setIsReady] = useState(false);
  const market = process.env.GATSBY_MARKET || '';

  useLayoutEffect(() => {
    setupFeatureFlags();

    return () => configCatClient.dispose();
  }, []);

  const setupFeatureFlags = async () => {
    try {
      await configCatClient.forceRefreshAsync();
      const response = await configCatClient.getAllValuesAsync({
        custom: { market, canIncludeInCampaignABTest: 'true', destination: `${destinationSlug}`, ch: `${ch}` }, // with template literals we cheat ConfigCat so it doesn't show the warning (i.e. we pass undefined as a string)
        identifier,
      });

      let flagsObj: Partial<FlagState> = {};
      for (const item of response) {
        flagsObj = { ...flagsObj, [item.settingKey]: item.settingValue };
      }
      setFlags(flagsObj);
      trackFeatureFlags(flagsObj as FlagState);

      setIsReady(true);
    } catch (error) {
      setIsReady(true);
      sendToSentry(error);
    }
  };

  return <FeatureFlagContext.Provider value={{ ...flags, isReady }}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlagContext = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw Error('useFeatureFlagContext must be used within FeatureFlagContext');
  }

  return context;
};
