export enum Market {
  DE = 'tourlane.de',
  UK = 'tourlane.co.uk',
  US = 'tourlane.com',
  FR = 'tourlane.fr',
  NL = 'tourlane.nl',
}

export enum Locale {
  DE = 'de-DE',
  US = 'en-US',
  FR = 'fr-FR',
}

export type LocaleType = `${Locale}`;

export type MarketType = `${Market}`;
