import { FC, ReactNode } from 'react';

import { FeatureFlags } from './FeatureFlag.config';
import { useFeatureFlagContext } from './FeatureFlag.context';

interface FeatureFlagProps {
  fallbackComponent?: ReactNode;
  flagId: FeatureFlags;
  children?: ReactNode;
}

const FeatureFlagWrapper: FC<FeatureFlagProps> = ({ children, fallbackComponent, flagId }) => {
  const context = useFeatureFlagContext();

  if (!context[flagId]) return <>{fallbackComponent}</>;

  return <>{children}</>;
};

export default FeatureFlagWrapper;
